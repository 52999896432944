const objects = {
    "object0" : {
        "pictureBefore": "embankment.png",
        "pictureAfter": "embankment.png",
        "title": "Стенка канала Петровского дока, участок №3, 2019 г.",
        "text": "Консультационные услуги по первоочередным противоаварийным мероприятиям участка стенки набережной по заказу ООО «МИДАС» для нужд СПб ГБУ «Мостотрест» и Администрации Кронштадтского района. В рамках консультационных услуг было проведено дополнительное обследование аварийного участка стенки набережной канала Петровского дока, в результате которого выявилось нарушение каменной кладки из бутового заполнения тела стенки набережной. Было предложено провести инъектирование на глубину сооружения надводной части (2,5 м) с шагом 0,6 м для восстановления несущей способности каменной кладки. Также разработана система технических мероприятий, направленных на устранение последствий вывала каменной кладки на аварийном участке пломбированием. Проект согласован с КГИОП. Работы выполнены с понтона в 2020 г."
    },

    "object1" : {
        "pictureBefore": "brick_building.png",
        "pictureAfter": "brick_building.png",
        "title": "Дворовая территория дома №37Б литер А по ул. Профессора Попова, ЛЭТИ, 2019 г.",
        "text": "По заказу Санкт-Петербургского государственного электротехнического университета «ЛЭТИ» им. В.И. Ульянова (Ленина) разработан проект по устройству плаца в асфальтобетонном покрытии со вспомогательными площадками для отработки техники построения студентами военной кафедры университета. Проектная документация согласована с КГА и Комитетом по благоустройству."
    },

    "object2" : {
        "pictureBefore": "white_building.png",
        "pictureAfter": "white_building.png",
        "title": "Дворовая территория дома №45 по ул. Ленина, 2018 г.",
        "text": "Проект благоустройства элементов дворовой территории по заказу МАМОМО «Чкаловское»: замена асфальтобетонного покрытия на мощение тротуарной плиткой; предусмотрено устройство водоприемников под водосточные трубы, устройство гидроизоляции по краю мощения, регулировка крышек коверов, выравнивание бортового камня вдоль ограждения. Документация согласована с КГА. Работы выполнены в 2019 г."
    },

    "object3" : {
        "pictureBefore": "brown_building.png",
        "pictureAfter": "brown_building.png",
        "title": "Дворовая территория дома №50 по Каменноостровскому проспекту, 2019 г.",
        "text": "Проект благоустройства элементов дворовой территории по заказу МАМОМО «Чкаловское»: замена асфальтобетонного покрытия на мощение тротуарной плиткой; предусмотрено устройство водоприемных лотков под водосточные трубы, устройство гидроизоляции по краю мощения, регулировка крышек колодцев, установка облегченного бортового камня. Документация согласована с КГА. Работы выполнены в 2020 г."
    },

    "object4" : {
        "pictureBefore": "embankment.png",
        "pictureAfter": "embankment.png",
        "title": "Дноуглубление участка акватории в г. Новая Ладога, 2020 г.",
        "text": "Для нужд ГКУ «Управление строительства Ленинградской области» и ГУ МЧС по заказу ООО «Монтаж-Строй Северо-Запад» в рамках корректировки проектной документации, разработанной ООО «БАЛТПРОЕКТ», выполнены проектно-изыскательские работы по дноуглублению с устройством слипа, включающего в себя гидротехнические и конструктивные решения укрепления подводного откоса, мероприятия по охране окружающей среды, технологические решения по производству работ дноуглубления. Проектная документация согласована с Росрыболовством. Работы выполнены в 2021 г."
    },

    "object5" : {
        "pictureBefore": "brick_building.png",
        "pictureAfter": "brick_building.png",
        "title": "Корректура проектной документации «Благоустройство наб. Достоевского с частичным берегоукреплением», г.Старая Русса, 2021 г",
        "text": "Для нужд администрации г. Старая Русса по заказу ООО «НТЦ «Северо-Запад Проект» в рамках корректуры проектной документации разработан раздел «Схема планировочной организации земельного участка» в составе краткой пояснительной записки, решений по благоустройству с элементами озеленения (дендроплан), разбивочно-посадочного чертежа, чертежа по вертикальной планировки с картограммой земляных масс, ведомости объемов работ по озеленению. Работы выполнены в 2021 г."
    },

    "object6" : {
        "pictureBefore": "white_building.png",
        "pictureAfter": "white_building.png",
        "title": "Консультационные услуги по объекту культурного наследия «Реальное училище», 2021г.",
        "text": "Для нужд администрации Кронштадтского района по заказу ООО «МИДАС» выполнена проверка проектной документации с корректировкой ведомостей объемов работ на ремонтно-реставрационные работы с приспособлением для современного использования здания школы."
    },

    "object7" : {
        "pictureBefore": "brown_building.png",
        "pictureAfter": "brown_building.png",
        "title": "Корректировка раздела ПОС для реконструкции объекта «Форт «Кроншлот», 2022 г.",
        "text": "Для нужд Минобороны РФ по заказу ООО «ГЕОИЗОЛ Проект» выполнена корректировка раздела «Проект организации строительства» в части описания технологических процессов с разработкой чертежей по Северной куртине. Определена технология работ по бетонированию Северной куртины и берегоукреплению 257 п.м, определена технология подъёма посторонних предметов со дна акватории, разработаны чертежи по СВСиУ"
    }
}

export default objects