import "./AboutCompany.scss";
import React from "react";

const Mission = () => {
  return (
    <section className="about-company">
      <h2 className="about-company__title">О компании</h2>
      <p className="about-company__subtitle">
          Будучи основателем и генеральным директором компании, я Георгий Дружков, рад приветствовать вас здесь, на нашем сайте от лица всей нашей дружной команды ООО «ПСК «Надёжный Партнёр».
          <br/><br/>Мы – команда специалистов, различного профиля, имеющая успешный многолетний опыт разработки проектно-рабочей документации по капитальному ремонту и реставрации инфраструктурных объектов культурного наследия, объектов инженерной подготовки территории и благоустройства.
          <br/><br/>Достигнутое за многолетнюю деятельность качество экспертизы, осуществляемой нами с самого начала нашей деятельности в различных областях градостроительного проектирования, позволяет нам в целом быть действительно Надежным Партнёром для всех своих Заказчиков.
          <br/><br/>Набережные и мосты, парки и пруды, дворовое благоустройство и инженерная инфраструктура, берегоукрепление и временные павильоны – всё это зона компетентности компании ПСК «Надежный Партнер».
      </p>
    </section>
  );
};

export default Mission;
